export interface Announcement {
  id: string;
  parent: string;
  author: string;
  title: string;
  text: string;
  emoji: string;
  startDate: Date;
  endDate: Date;
  type: EAnnouncementType;
  pinned: boolean;
  pinnedOnAlarmPerspective: boolean;
  activeState: EAnnouncementActiveState;
  admin: boolean;
  category: EAnnouncementCategory;
}

export enum EAnnouncementActiveState {
  ACTIVE, FUTURE, PAST
}

export enum EAnnouncementType {
  INFO, HINT, WARNING
}

export enum EAnnouncementCategory {
  _1, _2, _3, _4, _5, _6, _7, _8, _9
}

export interface AnnouncementResponsePaginated {
  content: Announcement[],
  totalPages: number,
  totalElements: number,
  size: number,
  number: number
}

export interface AnnouncementResponses {
    announcements: AnnouncementResponsePaginated;
    globalAnnouncements: Announcement[];
}