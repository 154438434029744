import * as angular from "angular";
import {IScope} from "angular";
import RestService from "../../../../services/rest.service";
import {SinkResponse, ESinkType} from "../../../../data/sinks.data";

export default class EditFe2sinkModal {
  public $uibModalInstance: any;
  public $scope: IScope;
  public restService:RestService;
  public okFunction: any;

  public pwd:string;
  public receiver:string;
  public signature:string;
  public allowStatusTransfer:boolean;

  public isSaving:boolean= false;
  public isNew:boolean= false;
  public fe2Sink: SinkResponse;
  public link:string;

    constructor($uibModalInstance: any, $scope: angular.IScope, restService:RestService, sink:SinkResponse, isNew:boolean, okFunction) {
    this.$uibModalInstance = $uibModalInstance;
    this.$scope = $scope;
    this.restService = restService;
    this.okFunction= okFunction;
    // to display fitting text in button
    this.isNew = isNew;
    this.fe2Sink =sink;
    this.receiver= this.fe2Sink.data[Parameters.receiver];
    this.pwd= this.fe2Sink.data[Parameters.password];
    this.signature= this.fe2Sink.data[Parameters.signature];
    this.allowStatusTransfer= this.fe2Sink.data[Parameters.allowStatusTransfer] === 'true';
    this.link = "https://alamos-support.atlassian.net/wiki/x/BQD_P";

    }

  cancel(){
    // no reload if no change was made
    this.okFunction(false);
    this.$uibModalInstance.close();
  }
  save() {
    this.isSaving = true;
    this.fe2Sink.data[Parameters.receiver]=  this.receiver;
    this.fe2Sink.data[Parameters.password]= this.pwd;
    this.fe2Sink.data[Parameters.allowStatusTransfer] = this.allowStatusTransfer;
    this.restService.saveSink(this.fe2Sink).then(() => {
      this.isSaving = false;
    }).finally(() => {
      // reload table on save
      this.okFunction(true);
      this.$uibModalInstance.close();

    });
  }
}
export enum Parameters{
  receiver= 'receiver',
  password='password',
  signature='signature',
  allowStatusTransfer='allowStatusTransfer'
}