'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import { ERelaisState, ERelaisType, Relais } from "../../../../data/admin.data";
import {RolePrivilege} from "../../../../data/privileges.enum";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";

require('./admin.relais.tablerow.component.css');

export default class RelaisTablerowComponent {
  public restrict: string;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA'
    this.scope = {
      relais: '='
    }
    this.template = require('./admin.relais.tablerow.component.html');

    this.controller = RelaisTablerowComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//parking
/* @ngInject */
class RelaisTablerowComponentController {
  public $scope: IScope;
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public listeners: Function[] = [];
  public restService: RestService;
  public isLoading = false;
  public relais: Relais;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, restService: RestService, public privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$log = $log;
    this.$rootScope = $rootScope;
    this.restService = restService;

    this.$scope.$watch('ctrl.relais', (oldValue, newValue) => {
      if (newValue) {
        this.initListeners();
      }
    });
  }

  initListeners() {
    //Wait for new account
    this.listeners.push(
      this.$rootScope.$on('relais.change.' + this.relais.id, (event, data) => {
        this.$log.info(data);
        this.relais.state = data.state;
        this.$scope.$apply();
      })
    );

    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  switchRelais() {
    if (!this.privilegeService.has(RolePrivilege.Station_Relais_Trigger)) {
      return;
    }
    if (this.relais.state === ERelaisState.ON && this.relais.type === ERelaisType.IMPULS) {
      return;
    }

    let newState = ERelaisState.ON;
    if (this.relais.state === ERelaisState.ON) {
      newState = ERelaisState.OFF;
    } else {
      newState = ERelaisState.ON;
    }


    this.isLoading = true;
    this.restService.switchRelais(this.relais.id, newState)
      .catch(error => {
        this.$log.error(error);
      })
      .finally(() => {
        this.isLoading = false;
        this.$scope.$apply();
      });
  }
}