'use strict';

export default class AvailabilityWeekComponent {
  constructor() {
    this.restrict = 'E',
      this.scope = {
        person: '=',
        threshold: '=',
        week: '='
      }
    this.template = require('./week.component.html');

    this.controller = AvailabilityWeekController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//availabilityWeek
/* @ngInject */
class AvailabilityWeekController {
  constructor($scope, $rootScope, $log, dataService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.dataService = dataService;

    this.$scope.$watch('ctrl.week', (week) => {
      this.init();
    })
  }

  init() {
    // INIT
    this.hours = [];
    for (var i = 0; i < 24; i++) {
      this.hours.push(i);
    }
    var now = new Date();
    this.currentHour = now.getHours();


    if (angular.isUndefined(this.week)) {
      // No week loaded

      // Init empty datamodel
      this.week = {
        entries: [],
        startDate: this.selectedDate,
        endDate: this.selectedDate
      };

      for (var i = 0; i < 7; i++) {
        var day = {
          baseState: 'NONE',
          entries: [],
          availabilityPercentage: -1
        };
        for (var ii = 0; ii < 24; ii++) {
          day.entries.push({
            state: 'NONE',
            info: ''
          });
        }
        this.week.entries.push(day);
      }

      // Init
      this.useDate = true;
      this.selectedDate = now;
      this.selectedYear = now.getFullYear();

      // Load from server
      this.load();
    } else {
      // Week already selected
      this.selectedWeek = this.week.kw;
      this.selectedYear = this.week.year;
      this.useDate = false;
    }
  }

  changeDate() {
    this.useDate = true;
    this.load();
  }

  load() {
    this.$rootScope.isAvailabilityLoading = true;
    if (this.useDate) {
      this.dataService.loadWeeklyAvailabilityByDate(this.person, this.selectedDate, (response) => {
        this.$log.info(response);
        this.$rootScope.isAvailabilityLoading = false;
        this.week = response;
        this.selectedYear = response.year;
        this.selectedWeek = response.kw;
      }, (err) => {
        this.$rootScope.isAvailabilityLoading = false;
        // Could not load weekly availability
        this.$log.error('Could not load weekly availability');
        this.$log.error(err);
      });
    } else {
      this.dataService.loadWeeklyAvailability(this.person, this.selectedWeek, this.selectedYear, (response) => {
        this.$log.info(response);
        this.$rootScope.isAvailabilityLoading = false;
        this.week = response;
      }, (err) => {
        this.$rootScope.isAvailabilityLoading = false;
        // Could not load weekly availability
        this.$log.error('Could not load weekly availability');
        this.$log.error(err);
      });
    }
  }

  /**
   * Select the next week
   */
  nextWeek() {
    if (this.selectedWeek === 52) {
      this.selectedWeek = 1;
      this.selectedYear++;
    } else {
      this.selectedWeek++;
    }
    this.useDate = false;
    this.load();
  }

  /**
   * Select the last week
   */
  lastWeek() {
    if (this.selectedWeek === 1) {
      this.selectedWeek = 52;
      this.selectedYear--;
    } else {
      this.selectedWeek--;
    }
    this.useDate = false;
    this.load();
  }
}