import { Person, PersonsAddressbookResponse, PersonSearchQueryResult, PersonSimple } from "../../../../data/person.data";
import { IRootScopeService, IScope } from "angular";
import RestService from "../../../../services/rest.service";
import * as angular from "angular";
import { SortParams } from "../../../views/addressbook.view.component/addressbook.view.component";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";

require('./edit.person.substitutes.component.css');
export default class PersonSubstitutesComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E'
    this.template = require('./edit.person.substitutes.component.html');
    this.scope = {
      person: '='
    };
    this.controller = PersonSubstitutesController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}
class PersonSubstitutesController {

  public person: Person;
  public addedPersons: PersonSearchQueryResult[];

  public isLoadingPersons: boolean;
  public addressbook: PersonsAddressbookResponse;
  public params = {
    sortType: 'displayName', // set the default sort type
    sortReverse: false, // set the default sort order
    searchFilter: '', // set the default search/filter term
    currentPage: 0,
    totalElements: 0,
    pageSize: 25
  } as SortParams;
  public priv:PrivilegeService;
  public hasPersEdit:boolean= false;

  constructor(public $scope: IScope, public $rootScope: IRootScopeService, public restService: RestService, privilegeService: PrivilegeService) {
    this.priv = privilegeService;
    this.hasPersEdit = this.priv.has(RolePrivilege.Addressbook_Persons_Edit);

    this.$scope.$watch("ctrl.person", ((newValue: Person, oldValue) => {
      if (angular.isDefined(newValue)) {
        this.person = newValue;
      } else if (angular.isDefined(oldValue)) {
        this.person = oldValue;
      }
      if (angular.isDefined(this.person)) {
        this.addedPersons = []
        this.person.substitutes = []
        if (this.isDefined(this.person.substituteResponse) && this.isDefined(this.person.substituteResponse.substitutes)) {
          this.addedPersons = this.person.substituteResponse.substitutes
          this.updateIdList()
        }
        this.loadPersons();
        this.$scope.$apply();
      }
    }))


  }

  public isPersonAdded(personId: string): boolean {
    if (personId === this.person.personID) return true; // TODO: can we somehow completely remove the person itself from the list without changing the backend (maintaining pagination with 25 persons per page)?
    let list: PersonSearchQueryResult[] = this.addedPersons.filter(p => p.personID === personId);
    return list.length > 0;
  }

  public addPerson(person: PersonSimple): void {
    if (!this.isPersonAdded(person.personID)) {
      this.addedPersons.push({ personID: person.personID, displayName: person.displayName } as PersonSearchQueryResult);
      this.updateIdList();
    }
  }

  public removePerson(person: PersonSearchQueryResult): void {
    let list: PersonSearchQueryResult[] = this.addedPersons.filter(p => p.personID === person.personID);
    if (list.length > 0) {
      this.addedPersons.splice(this.addedPersons.indexOf(list[0]), 1);
      this.updateIdList();
    }
  }

  private updateIdList(): void {
    this.person.substitutes = this.addedPersons.map(person => person.personID)
  }

  public moveUp(person: PersonSearchQueryResult) {
    let i = this.addedPersons.indexOf(person);
    let temp = this.addedPersons[i-1]
    this.addedPersons[i-1] = person;
    this.addedPersons[i] = temp;
    this.updateIdList();
  }

  public moveDown(person: PersonSearchQueryResult) {
    let i = this.addedPersons.indexOf(person);
    let temp = this.addedPersons[i+1]
    this.addedPersons[i+1] = person;
    this.addedPersons[i] = temp;
    this.updateIdList();
  }

  private isDefined(object: any): boolean {
    return object !== undefined && object !== null;
  }

  private loadPersons() {
    this.isLoadingPersons = true;
      this.restService.loadPersons(this.params.currentPage === 0 ? 0 : this.params.currentPage - 1,
        this.params.searchFilter,
        this.params.sortReverse ? 'DESC' : 'ASC',
        this.params.pageSize,
        undefined,
        false)
        .then((result: PersonsAddressbookResponse) => {
          this.addressbook = result;
          this.params.totalElements = this.addressbook.totalElements;
          this.params.totalPages = this.addressbook.totalPages;
        }).finally(() => {
          this.isLoadingPersons = false;
          this.$scope.$applyAsync();
        });
    }

  search() {
    this.params.currentPage = 0;
    this.loadPersons();
  }

  resetSearchAndReload() {
    this.params.searchFilter = '';
    this.search();
  }

}
