export interface CustomMapLayer {
    type: ECustomMapLayerType
    name: string;
    url: string;
    wmsParameters: WMSParameters
}

export interface WMSParameters {
    layers: string;
    styles: string;
    format: string;
    version: string;
}

export enum ECustomMapLayerType {
    TILE = "TILE", WMS = "WMS"
}