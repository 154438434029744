'use strict';
//objectFeedbackEntry
export default class ObjectFeedbackEntryComponent {
  constructor() {
    this.restrict = 'A'
    this.template = require('./object.feedback.entry.component.html');
    this.scope = {
      entry: '=',
      isEditable: '='
    };
    this.controller = ObjectFeedbackEntryComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectFeedbackEntryComponentController {
  constructor() {
    this.states = ['PENDING', 'CREATED', 'ACCEPTED', 'DECLINED', 'RESOLVED'];
  }

  isCreated(item) {
    return item.state === 'CREATED';
  };

  isNotCreated(item) {
    return item.state !== 'CREATED';
  };
}