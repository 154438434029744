'use strict';
import ErrorService from "../../../../services/error.service";
import {ILogService, IRootScopeService, IScope} from "angular";
import RestService from "../../../../services/rest.service";

require('./import.vehicle.modal.css')
/* @ngInject*/
export default class ImportVehicleModalController{
  public $uibModalInstance: any;
  public uploader: any;
  public isLoading: boolean = false;
  public errorService: ErrorService;
  public $log: ILogService;
  public $http: any;
  public restService: RestService;
  public $scope: IScope;
  public $rootScope: IRootScopeService;

  constructor($scope:IScope, $rootScope: IRootScopeService, $uibModalInstance,uploader,errorService: ErrorService, $log: ILogService, $http, restService:RestService) {
    this.$uibModalInstance = $uibModalInstance;
    this.$scope = $scope;
    this.$rootScope= $rootScope;
    this.uploader = uploader;
    this.errorService = errorService;
    this.$log = $log;
    this.$http = $http;
    this.restService = restService;

    this.uploader.onCompleteAll= ()=>{
      this.$log.info('onCompleteAll');
      this.$uibModalInstance.close();
    }
    this.uploader.onBeforeUploadItem = (item)=>{
      this.$log.info("import");
      item.url = this.restService.getBaseUrl() + '/vehicles/upload'
    };

    this.uploader.headers = {
      Authorization: this.$http.defaults.headers.common.Authorization
    }
  }

  cancel(){
    this.$uibModalInstance.close();
  }

  import(){
    this.isLoading = true;
    this.uploader.onErrorItem = (item, response, status) =>{
      this.errorService.notifyWithText(response.message);
    };
    this.uploader.uploadAll();
  }
}