'use strict';

import {AdminAddUnitRequest, EUnitType} from "../../../../data/unit.data";
import {Vehicle, VehicleNameIDResponse} from "../../../../data/vehicles.data";
import RestService from "../../../../services/rest.service";
import {IScope} from "angular";
import {User} from "../../../../data/admin.data";

require("./admin.add.unit.modal.css")

/* @ngInject */
export default class AdminAddUnitModalController {
    public $scope:IScope;

    public unitRequest: AdminAddUnitRequest;
    public selectedVehicle: VehicleNameIDResponse = undefined;
    public vehicleSearch: string = '';
    public vehicles: VehicleNameIDResponse[] = [];
    public user:User;

    constructor(private $uibModalInstance: any, type: EUnitType,$scope:IScope,
        public customCode: boolean, public restService: RestService, user:User) {
      this.user=user;
      this.$scope= $scope;
        this.unitRequest = {
            type,
            name: "",
            code: ""
        } as AdminAddUnitRequest
    }


  /**
   * Search for vehicles
   */
  searchForVehicle() {
    if (this.vehicleSearch === '') {
      this.vehicles = [];
      return;
    }
    if (this.vehicleSearch.length < 2) {
      // Too few characters
      return;
    }

    this.restService.searchForVehiclesAccessibleForUser(this.vehicleSearch, this.user.id).then(result => {
      this.vehicles = result as VehicleNameIDResponse[];
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }

  /**
   * Remove the vehicle mapping
   */
  removeVehicle() {
    this.selectedVehicle = undefined;
  };

  /**
   * Set the mapping between vehicle and aMobile
   */
  addVehicle(vehicle: Vehicle) {
    this.selectedVehicle = vehicle;
    this.vehicleSearch = '';
    this.vehicles = [];
  };

    public close(): void {
        this.$uibModalInstance.dismiss();
    }

    public save(): void {
      if (this.unitRequest.type === EUnitType.QUEUE && this.selectedVehicle) {
        this.unitRequest.connectedVehicleId = this.selectedVehicle.id
      }
      this.$uibModalInstance.close(this.unitRequest);
    }

}