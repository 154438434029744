'use strict';
//Controller for sending help
/* @ngInject */
export default class SendHelpModalController{
  constructor($scope, $rootScope, $log, $uibModalInstance, restService){
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;

  }

  sendEmailToAdmin (text) {
    //Send email
    this.isLoading = true;

    this.restService.sendEmailToAdmin(text,  (response) => {
        this.isLoading = false;
        this.$uibModalInstance.close();
      },
       (response) =>{
        //Error occured
        this.isLoading = false;
        this.$log.error(response);
      });
  }

  cancel () {
    this.$uibModalInstance.close();
  }
}
