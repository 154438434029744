'use strict';

import {IScope} from "angular";
import {BaseField} from "../../../../data/basefield.data";
import RestService from "../../../../services/rest.service";
import {SortParams} from "../../../views/addressbook.view.component/addressbook.view.component";
import {IOprint} from "../../../../data/ioprint.data";

require('./ioprint.base.field.css');

export default class IOPrintBaseFieldComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./ioprint.base.field.html');
    this.scope = {
      field: '=',
      isDerivedFromTemplate: '='
    };
    this.controller = IOPrintBaseFieldController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}


/* @ngInject */
class IOPrintBaseFieldController {
  public field: BaseField;
  public restService: RestService;
  public $scope: IScope;
  public allAvailableIOPrints: IOprint[];
  public isLoading = false;
  private params = {
    currentPage: 0,
    totalElements: 0,
    pageSize: 20
  } as SortParams;

  constructor(restService: RestService, $scope: IScope) {
    this.restService = restService;
    this.$scope = $scope;

    this.loadAvailableIOPrints();
    $scope.$watch('ctrl.field', (newValue) => {
      if (newValue) {
        this.loadAvailableIOPrints();
      }
    });
  }


  isSelected(ioPrint: IOprint) {
    for (var i = 0; i < this.field.value.length; i++) {
      if (this.field.value[i] === ioPrint.id) {
        return true;
      }
    }
    return false;
  }

  select(ioPrint: IOprint) {
    if (this.isSelected(ioPrint)) {
      //Remove
      var indexToDelete = -1;
      for (var i = 0; i < this.field.value.length; i++) {
        if (this.field.value[i] === ioPrint.id) {
          indexToDelete = i;
          break;
        }
      }
      if (indexToDelete > -1) {
        this.field.value.splice(indexToDelete, 1);
      }
    } else {
      //Add
      this.field.value.push(ioPrint.id);
    }
  }


  loadAvailableIOPrints() {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.restService.getIOprintsAsUser()
        .then((result: IOprint[]) => {
          this.allAvailableIOPrints = result;
          this.checkSelectedIOPrints();
        }).finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
        resolve();
      });
    });
  }

  checkSelectedIOPrints() {
    let av = this.allAvailableIOPrints.map((x) => x.id)
    let updated = [];
    for (var i = 0; i < this.field.value.length; i++) {
      var index = av.indexOf(this.field.value[i]);
      if (index >= 0) {
        updated.push(this.field.value[i])
      }
    }
    this.field.value = updated;

  }
}
