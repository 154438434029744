'use strict';

//alarmCenterView
export default class AlarmCenterComponent {
  constructor() {
    this.restrict = 'E'
    this.template = require('./alarm.center.component.html');
    this.scope = {
      account: '=',
      users: '=',
      units: '=',
      grid: '='
    };
    this.controller = AlarmCenterComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AlarmCenterComponentController {
  constructor($scope, $uibModal, $log, $rootScope, restService) {
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.restService = restService;

    this.listeners = [];
    this.column1 = [];
    this.column2 = [];
    this.column3 = [];
    this.column4 = [];


    this.listeners.push(this.$rootScope.$on('grid.updated', () => {
      this.init();
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });

    this.$scope.$watch('ctrl.grid', (grid) => {
      if (angular.isDefined(grid)) {
        this.init();
      }
    });
  }

  /**
   * Init data model
   */
  init() {
    this.column1 = [];
    this.column2 = [];
    this.column3 = [];
    this.column4 = [];

    this.$log.debug(this.grid);

    this.prepareColumn(this.column1, this.grid.column1);
    this.prepareColumn(this.column2, this.grid.column2);
    this.prepareColumn(this.column3, this.grid.column3);
    this.prepareColumn(this.column4, this.grid.column4);
  };

  /**
   * Add all templates to column
   * @param {} column 
   * @param {*} gridColumn 
   */
  prepareColumn(column, gridColumn) {
    this.restService.getAlarmTemplatesByIdList(gridColumn)
    .then(templates => {
      templates.forEach(t => column.push(t));
    })
    .catch(err => {
      this.$log.error(err);
    })
    .finally(() => {
      this.$scope.$apply()
    })
  };

  hasTemplates() {
    if(this.column1.length > 0) return true;
    if(this.column2.length > 0) return true;
    if(this.column3.length > 0) return true;
    if(this.column4.length > 0) return true;
    return false;
  }

  /**
   * Alert a template
   * @param {} template 
   */
  alert(template) {
    this.$uibModal.open({
      template: require('../../../modals/pipeline/confirm.template.modal/confirm.template.modal.html'),
      controller: 'ConfirmTemplateModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        template: () => {
          return template;
        }
      }
    });
  };
}
