'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import { WmsLayerData, WmsLayerDataUpdateRequest, WmsServerData } from "../../../../../data/custom.map.server.data";
import { CustomMapLayer, ECustomMapLayerType } from "../../../../../data/customMapLayer.data";
import RestService from "../../../../../services/rest.service";

require('./admin.map.component.css');

export default class AdminMapSettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.map.component.html');
    this.scope = {
    };
    this.controller = AdminMapComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}

/* @ngInject */
class AdminMapComponentController {
  private isLoading: boolean = false;
  private layerSettings: CustomMapLayer;
  private overlayServerData: WmsServerData;
  private overlayLayers: WmsLayerData[];


  constructor(private $scope: IScope, 
    private $rootScope: IRootScopeService,
    private restService: RestService,
    private dataService,
    private $log: ILogService
  ) {
    this.load();
  }


  /**
   * Load map settings
   */
  load() {
    this.layerSettings = this.dataService.getAccount().customMapLayer;
    if (!this.layerSettings) {
      this.layerSettings = {
        type: ECustomMapLayerType.TILE,
        name: "",
        url: "",
        wmsParameters: {
          layers: "",
          styles: "",
          format: "",
          version: ""
        }
      };
    }
    this.restService.loadWmsServerData()
      .then(data => this.overlayServerData = data)
      .then(() => this.restService.loadAvailableWmsLayers())
      .then(layers => this.overlayLayers = layers)
      .catch(err => this.$log.error(err))
      .finally(() => this.$scope.$applyAsync());
  }

  /**
   * Save map settings
   */
  saveLayer() {
    this.isLoading = true;
    this.dataService.getAccount().customMapLayer = this.layerSettings;
    this.restService.saveCustomMapLayer(this.layerSettings).then(response => {
      this.layerSettings = response;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  private setTypeTile(): void {
    this.setType(ECustomMapLayerType.TILE)
  }

  private setTypeWms(): void {
    this.setType(ECustomMapLayerType.WMS)
  }

  private setType(type: ECustomMapLayerType): void {
    this.layerSettings.type = type;
    if (type === ECustomMapLayerType.TILE) return;
    if (!this.layerSettings.wmsParameters) {
      this.layerSettings.wmsParameters = {
        layers: "",
        styles: "",
        format: "",
        version: ""
      }
    }
  }

  saveOverlay() {
    this.isLoading = true;
    this.restService.saveWmsServerData(this.overlayServerData)
      .then(res => this.overlayServerData = res)
      .then(() => this.restService.loadAvailableWmsLayers())
      .then(layers => this.overlayLayers = layers)
      .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
  }

  

  toggleLayer(layer: WmsLayerData) {
    let updateRequest = {
      active: !layer.active,
      category: layer.category
    }
    this.updateLayer(layer.name, updateRequest);
  }

  updateLayerCategory(layer: WmsLayerData, category: string) {
    let updateRequest = {
      active: layer.active,
      category
    }
    this.updateLayer(layer.name, updateRequest);
  }


  private updateLayer(name: string, updateRequest: WmsLayerDataUpdateRequest) {
    this.isLoading = true;
    this.restService.updateWmsLayerData(name, updateRequest)
      .then(res => this.overlayLayers.filter(l => l.name === res.name).forEach(l => l.active = res.active))
      .catch(err => this.$log.error(err))
      .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
  }
}

