'use strict';

require('./column.component.css');

export default class AvailabilityColumnComponent {
  constructor() {
    this.restrict = 'E',
      this.scope = {
        onlineservice: '=',
        settings: '='
      }
    this.template = require('./column.component.html');

    this.controller = AvailabilityColumnController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AvailabilityColumnController {
  constructor($scope, $rootScope, $log, helperService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.helperService = helperService;

    this.statistic = {};
    this.groups = {};

    this.listeners = [];

    this.$scope.$watch('ctrl.onlineservice', (nVal) => {
      if (angular.isUndefined(nVal)) {
        return;
      }
      //Watch for changes
      this.createGroups();
    });
  }

  initListeners() {
    this.listeners.push(this.$rootScope.$on('availability_filtered', (event, data) => {
      this.settings = data;
      this.createGroups();
    }));


    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  isFunctionExcluded(func, person) {
    if (angular.isDefined(person.excludedFunctions)) {
      return person.excludedFunctions.indexOf(func) !== -1;
    }
    return false;
  }

  /**
   * Create groups for UI
   */
  createGroups() {
    this.$log.debug('Updating group columns...');

    this.groups = [];

    var tmpAvailable = [];
    var tmpNotAvailable = [];
    var tmpTempNotAvailable = [];

    var defaultGroup = {
      data: [],
      name: "Standardgruppe"
    }

    for (var i = 0; i < this.onlineservice.data.lstOfAvailabilities.length; i++) {
      var person = this.onlineservice.data.lstOfAvailabilities[i];

      // Check filtered availability
      if (this.settings.filteredAvailability.includes(person.state)) {
        //Availability filtered
        continue;
      }


      this.helperService.addPersonToGroup(person, defaultGroup, this.groups);

      //Add to right availability group
      if (person.state === 'AVAILABLE') {
        tmpAvailable.push(person);
        continue;
      }
      if (person.state === 'NOT_AVAILABLE') {
        tmpNotAvailable.push(person);
        continue;
      }
      if (person.state === 'TEMP_NOT_AVAILABLE' || person.state === 'NONE') {
        tmpTempNotAvailable.push(person);
        continue;
      }

    }

    //Sum up statistic
    this.statistic.available = tmpAvailable.length;
    this.statistic.notAvailable = tmpNotAvailable.length;
    this.statistic.tempNotAvailable = tmpTempNotAvailable.length;
    this.statistic.total = tmpAvailable.length + tmpNotAvailable.length + tmpTempNotAvailable.length;

    //Add default group to list of groups
    if (defaultGroup.size !== 0) {
      this.groups.push(defaultGroup);
    }

    //Sort group
    for (var iSort = 0; iSort < this.groups.length; iSort++) {
      var group = this.groups[iSort];
      this.helperService.sortGroup(group);
    }

    //Sort groups
    this.groups.sort((a, b) => {
      return a.name > b.name;
    })

    //Create table data
    this.rows = [];
    if (this.groups.length > 2) {
      //3 columns
      var row;
      for (var i = 0; i < this.groups.length; i++) {
        if (i % 3 === 0) {
          if (angular.isDefined(row)) {
            this.rows.push(row);
          }
          //Create new row
          row = [];
        }
        row.push(this.groups[i]);
      }
      if (angular.isDefined(row)) {
        this.rows.push(row);
      }
    } else {
      //2 or less columns
      this.rows.push(this.groups);
    }
  }
}
