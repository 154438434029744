'use strict';
//Controller for availability

/* @ngInject */
export default class PersonAvailabiltyModalController {

  constructor($uibModalInstance, helperService, person) {
    this.$uibModalInstance = $uibModalInstance;
    this.helperService = helperService;
    this.person = person;
    this._color = this.helperService.getColorForAvailability(this.person.state);
  }

  close() {
    this.$uibModalInstance.close();
  };
}