'use strict';

export default class AvailabilitySummaryComponent {
  constructor() {
    this.restrict = 'E',
      this.scope = {
        onlineservice: '='
      }
    this.template = require('./summary.component.html');

    this.controller = AvailabilitySummaryController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//availabilitySummary
/* @ngInject */
class AvailabilitySummaryController {
  constructor($scope, $rootScope, $log, dataService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.dataService = dataService;

    this.invalidRules = [];
    this.isAvailabilityPositive = true;

    /**
     * Listen to rules changes
     */
    this.listener = this.$rootScope.$on('rules.updated', (event, allRules) => {
      this.updateUI(allRules);
    });

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listener();
    });

    this.$onInit = () => {
      // Load for the first time
      this.$log.debug('Loading availability rules for summary.component...');
      this.dataService.getAvailabilityRules(false, (allRules) => {
        // Filter for not valid rules only
        this.updateUI(allRules);
      }, (err) => {
        this.$log.error(err);
      });
    }
  }




  /**
   * Update UI
   * @param {} rules 
   */
  updateUI(allRules) {

    this.isAvailabilityPositive = this.onlineservice.statistic.available >= this.onlineservice.statistic.notAvailable;

    this.invalidRules = allRules.filter((validatedRule) => {
      if (validatedRule.rule.type === 'FEEDBACK') {
        return false;
      }
      return !validatedRule.valid;
    });
  }
}
