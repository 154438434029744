import angular from 'angular';
import 'angular-ui-bootstrap'
import services from '../../../services'


import UserDashboardComponentController from './user.dashboard.component/user.dashboard.component';
import FancyCheckboxComponentController from './checkbox/checkbox';
import PreviewComponentController from './preview/preview';
import SettingsAmobileLayersComponent from './settings.amobile.layers.component/settings.amobile.layers.component';
import SinkSettingsComponent from "./sink.settings.component/sink.settings.component";
import SinkTablerowComponent from "./sink.tablerow.component/sink.tablerow.component";

export default angular.module('FE2.components.misc', ['ui.bootstrap', services])
  //.controller('MainController', MainController)
  .directive('userDashboard', () => new UserDashboardComponentController)
  .directive('fancyCheckbox', () => new FancyCheckboxComponentController)
  .directive('preview', () => new PreviewComponentController)
  .directive('settingsAmobileLayers', () => new SettingsAmobileLayersComponent)
  .directive('sinkSettings', () => new SinkSettingsComponent)
  .directive('sinkTablerow', () => new SinkTablerowComponent)
  .name;
