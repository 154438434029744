import { EVehicleStatus, Vehicle, StatusEntryResponse } from './../../../../data/vehicles.data';
import { IScope, ILogService, IRootScopeService } from 'angular';
import angular = require("angular");
import { ParkingPosition } from '../../../../data/building.data';
import RestService from "../../../../services/rest.service";
import {UserAccount} from "../../../../data/account.data";
import PrivilegeService from '../../../../services/privilege.service';
import {RolePrivilege} from '../../../../data/privileges.enum';

'use strict';

require('./vehicle.tablerow.component.css');

export default class VehicleTableRowComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'EA'
    this.scope = {
      vehicle: '=',
      limit: '=',
      hideParkingPositionChooser: '=',
      hideStatus: '=',
      parkingPositions: '='
    }
    this.template = require('./vehicle.tablerow.component.html');

    this.controller = VehicleTableRowComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//vehicle
class VehicleTableRowComponentController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public restService: RestService;
  public dataService: any;
  public $uibModal: any;
  public status: any;
  public listeners: any;
  public parkingPositions: ParkingPosition[] = [];
  public color: any;
  public vehicle: Vehicle;
  public isLoading: boolean;
  public textColor: string;
  public account: UserAccount;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, dataService, $uibModal, restService: RestService,
    public privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.dataService = dataService;
    this.$uibModal = $uibModal;
    this.status = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'C'];
    this.listeners = [];
    this.restService = restService;
    if (this.dataService.hasAccount()){
      this.account = this.dataService.getAccount();
    }

    this.$scope.$watch('ctrl.vehicle', (oldValue, newVehicle) => {
      if (angular.isDefined(newVehicle)) {
        this.textColor = this.vehicle.statusTextColor;
        this.initListeners();
      }
    });


  }

  initListeners() {
    // Delete old listeners
    this.listeners.forEach((listener) => {
      listener();
    });
    this.listeners = [];

    //This listener is for later realtime status updates
    var changeEvent = 'status.change.' + this.vehicle.id;
    this.listeners.push(this.$rootScope.$on(changeEvent, (event, data: StatusEntryResponse) => {
      this.vehicle.status = data.status;
      this.vehicle.statusChangedTimestamp = data.timestamp;
      this.vehicle.statusColor = data.color;
      this.textColor = data.textColor;
      this.$scope.$apply();
    }));

    //Unregister listener
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  getName() {
    if (!this.vehicle) return '';
    if (this.vehicle.shortName) {
      return this.vehicle.shortName;
    }
    return this.vehicle.name;
  }
  deleteVehicle(){
    this.restService.deleteVehicle(this.vehicle.id).then(()=>{
      this.$rootScope.$emit('update.vehicles');
    }).catch(error=>{
      this.$log.error(error);
    });

  }
  /**
   * Set the parking position
   */
  setParkingPosition(parkingPosition: ParkingPosition) {
    this.isLoading = true;
    this.restService.setParkingPositionForVehicle(this.vehicle, parkingPosition).then((vehicle: Vehicle) => {
      this.vehicle = vehicle;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  };

  /**
   * Manually set status
   */
  setStatus(status) {
    this.$log.info('Setting status: ' + status);
    this.isLoading = true;
    this.dataService.updateVehicleStatus(this.vehicle, status, (vehicle: Vehicle) => {
      this.isLoading = false;
      if (status === '97' || status === '98') {
        // Dont do anything
        return;
      }
      this.vehicle.status = EVehicleStatus['STATUS_' + status];
      this.vehicle.source = 'MANUAL';
      this.$rootScope.$emit('status.change', this.vehicle);
    }, (err) => {
      this.isLoading = false;
    });
  }

  /**
   * Remove the parking position
   */
  removeParkingPosition() {
    this.isLoading = true;
    this.restService.removeParkingPositionForVehicle(this.vehicle).then((vehicle: Vehicle) => {
      this.vehicle = vehicle;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  };

  edit() {
    if (!this.privilegeService.has(RolePrivilege.Station_Vehicles_ReadDetails)) {
      return;
    }

    this.$uibModal.open({
      template: require('../../../modals/wache/vehicle.modal/vehicle.modal.html'),
      controller: 'VehicleModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        vehicle: () => {
          return this.vehicle;
        },
        vehicleId: () => {
          return this.vehicle.id;
        },
        okFunction: () => {
          return (vehicle: Vehicle) => {
            this.updateVisibleFieldsOfVehicleInRow(vehicle);
          };
        }
      }
    });
  }

  private updateVisibleFieldsOfVehicleInRow(vehicle: Vehicle) {
    this.vehicle.name = vehicle.name;
    this.vehicle.shortName = vehicle.shortName;
    this.vehicle.code = vehicle.code;
    this.vehicle.remark = vehicle.remark;
    this.vehicle.licensePlate = vehicle.licensePlate;
    this.vehicle.numberOfFaultMessages = vehicle.numberOfFaultMessages;
  }
}
