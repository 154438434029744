'use strict';

export default class AvailabilityBarComponent {
  constructor() {
    this.restrict = 'E',
    this.scope = {
      onlineservice: '='
    }
    this.template = require('./bar.component.html');

    this.controller = AvailabilityBarController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//availabilityBar
/* @ngInject */
class AvailabilityBarController {
  constructor($scope, $state) {
    this.$scope = $scope;
    this.$state = $state;
  }

  switch() {
    this.$state.go('main.availability');
  }
}