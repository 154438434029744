import angular from 'angular';
import 'angular-ui-bootstrap'
import services from '../../../services'
import QueueContainerComponentController from './queue.container/queue.container';
import QueueFinishedContainerComponentController from './queue.finished.container/queue.finished.container';
import QueueEntryComponentController from './queue.entry/queue.entry';

export default angular.module('FE2.components.queue', ['ui.bootstrap', services])
  .directive('queueContainer', () => new QueueContainerComponentController)
  .directive('queueEntry', () => new QueueEntryComponentController)
  .directive('queueFinishedContainer', () => new QueueFinishedContainerComponentController)

  .name;
