'use strict';
//objectVentilation
export default class ObjectVentilationComponent {
  constructor() {
    this.restrict = 'A'
    this.template = require('./object.ventilation.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectVentilationComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectVentilationComponentController {
  constructor($scope, $uibModal) {
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.types = ['RWA', 'MECHANICAL', 'NATURAL'];
    this.hasBMA = false;
    this.selected = undefined;

    /**
     * Init data changed
     */
    this.$scope.$watch('ctrl.alarmObject', () => {
      if (angular.isDefined(this.alarmObject)) {
        this.hasBMA = angular.isDefined(this.alarmObject.bma) && this.alarmObject.bma !== null;
      }
    });
  }

  /**
   * Add a new ventilation
   */
  addVentilation() {
    var data = {
      type: 'RWA',
      note: '',
      location: ''
    }

    if (angular.isUndefined(this.alarmObject.bma.ventilations)) {
      this.alarmObject.bma.ventilations = [];
    }
    this.alarmObject.bma.ventilations.push(data);
  };

  /**
   * Delete existing ventilation
   * @param {*} data
   */
  deleteVentilation(data) {
    this.alarmObject.bma.ventilations.splice(this.alarmObject.bma.ventilations.indexOf(data), 1);
  };

  /**
   * Show on map
   * @param {*} element 
   */
  showOnMap(element) {
    if (this.isEditable) {
      if (angular.isUndefined(element.coords)) {
        element.coords = {
          lat: this.alarmObject.address.coords.lat,
          lng: this.alarmObject.address.coords.lng,
          showOnMap: true
        }
      }
      this.$uibModal.open({
        template: require('../../../modals/objects/choose.on.map.modal/choose.on.map.modal.html'),
        controller: 'ChooseOnMapCtrl',
        controllerAs: 'ctrl',
        size: 'lg',
        resolve: {
          coords: () => {
            return element.coords;
          },
          okFunction: () => {
            return (coords) => {
              element.coords = coords;
            }
          }
        }
      });
    };
  }
}
