'use strict';

import {ProtocolEntry, ProtocolHistory} from "../../../../data/admin.data";
import RestService from "../../../../services/rest.service";

require('./admin.protocol.component.css');

export default class AdminProtocolComponent {
  public restrict: any;
  public template: any;
  public controller: any;
  public controllerAs: any;

  constructor() {
    this.restrict = 'E'
    this.template = require('./admin.protocol.component.html');
    this.controller = AdminProtocolComponentController;
    this.controllerAs = 'ctrl';
  }
}

/* @ngInject */
class AdminProtocolComponentController {
  public $uibModal: any;
  public $rootScope: angular.IRootScopeService;
  public $log: angular.ILogService;
  public restService: any;
  public Notification: any;
  public $translate: any;
  public data: ProtocolHistory;
  public limit: number;
  public listeners = [];
  public isLoading: boolean = true;
  public isSearching: boolean = false;
  public isDeleting = false;
  public searchFilter: string = '';

  constructor($rootScope: angular.IRootScopeService, $uibModal, $log: angular.ILogService, restService: RestService, Notification, $translate) {

    this.$uibModal = $uibModal;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.restService = restService;
    this.Notification = Notification;
    this.$translate = $translate;

    this.data = {
      currentPage: 1
    } as ProtocolHistory

    this.limit = 100;

    this.pageChanged();
  }

  openAdditional(entry: ProtocolEntry) {
    if (!entry.hasAdditionalInformation) {
      return
    }
    this.$uibModal.open({
      template: require('../../../modals/admin/protocol.additional.modal/protocol.additional.modal.html'),
      controller: 'ModalProtocolAdditionalController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        entryId: () => {
          return entry.id;
        }
      }
    });
  }

  pageChanged() {
    this.isLoading = true;
    //Current page starts with 0
    this.restService.getProtocol(this.data.currentPage - 1, this.limit, this.searchFilter, (response) => {
      this.isLoading = false;
      this.data = response.data;
      this.data.currentPage = this.data.currentPage + 1;
    }, (error) => {
      this.isLoading = false;
      this.$log.error(error);
    });
  };

  /**
   * Reset the search filter
   * */
  resetSearchAndReload() {
    this.searchFilter = '';
    this.data.currentPage = 1;
    this.pageChanged();
  };
}
