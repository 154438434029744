'use strict';

export default class RoadblockShiftbookModalController {
  constructor($uibModalInstance, $uibModal, $log, dataService) {
    this.$uibModalInstance = $uibModalInstance;
    this.$uibModal = $uibModal;
    this.dataService = dataService;
    this.$log = $log;

    this.roadblocks = [];

    this.getCurrentRoadblocksFromServer();

    this.account = this.dataService.getAccount();
  }

  close() {
    this.$uibModalInstance.close();
  }

  /**
   * Load current roadblocks from server
   */
  getCurrentRoadblocksFromServer() {
    this.$log.debug('Loading todays roadblocks...');
    this.isLoading = true;

    var now = new Date();
    var tomorrow = new Date();
    tomorrow.setDate(now.getDate() + 1);
    this.dataService.getShiftbookRoadblocks(true, now.getTime(), tomorrow.getTime(), true, (response) => {
      this.roadblocks = response;
      this.$log.debug(this.roadblocks);
      this.isLoading = false;
    }, (error) => {
      this.$log.error('Could not load todays events', error);
      this.isLoading = false;
    });
  }

  /**
   * Select a roadblock
   * @param {*} 
   */
  selectRoadblock(roadblock) {
    this.$uibModal.open({
      template: require('../../objects/edit.roadblock.modal/edit.roadblock.modal.html'),
      controller: 'EditRoadblockCtrl',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        roadblock: () => {
          return roadblock;
        }
      }
    });
  };
}
