'use strict';


export default class GroupSingleSelectComponent {
  constructor() {
    this.restrict = 'E';
    this.scope = {
      person: '=',
      group: '='
    };

    this.template = require('./group.single.select.component.html');

    this.controller = GroupSingleSelectComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class GroupSingleSelectComponentController {
  constructor($scope, $rootScope, $log) {
    this.$scope = $scope;
    // this.group = $scope.group;
    // this.person = $scope.person;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.listeners = [];
    //Check if is in group
    this.isChecked = false;
    this.initListeners();


  }

  initListeners() {
    //Adressbook changed
    this.listeners.push(
      this.$rootScope.$on("selection.alarmgroups", () => {
        this.checkIsChecked();
      })
    );
    // Unregister
    this.$scope.$on("$destroy", ()=> {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });

    this.$scope.$watch("ctrl.group",  (group) =>{
      if (angular.isUndefined(group)) {
        return;
      }
        this.checkIsChecked();

    });

  }

  checkIsChecked() {
    this.isChecked = false;
    for (var ii = 0; ii < this.group.personIds.length; ii++) {
      if (this.group.personIds[ii] === this.person.personID) {
        this.isChecked = true;
        break;
      }
    }
  }

  selectPerson() {

    this.$log.info("group: " + this.group);
    this.isChecked = !this.isChecked;
    var shouldBeDeleted = false;
    var existingIndex = -1;
    for (var i = 0; i < this.group.personIds.length; i++) {
      if (this.group.personIds[i] === this.person.personID) {
        //Already exists
        existingIndex = i;
        shouldBeDeleted = true;
        break;
      }
    }
    if (shouldBeDeleted) {
      this.$log.debug('Delete person from alarmGroup');
      this.group.personIds.splice(existingIndex, 1);
    } else {
      this.$log.debug('Add person to alarmGroup');
      this.group.personIds.push(this.person.personID);
    }
  };


}
