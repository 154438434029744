'use strict'


import { UserAccount } from "../../../data/account.data";
import {IRootScopeService, IScope, ILogService} from "angular";
import { AlarmDataSimple } from "../../../data/alarm.data";
import { EmergencyResponse, EmergencyVehicleSimple } from "../../../data/emergency.data";
import {RolePrivilege} from "../../../data/privileges.enum";
import PrivilegeService from "../../../services/privilege.service";
import RestService from "../../../services/rest.service";

require('./missions.view.component.scss');

/* @ngInject */
export default class MissionsController {
  public emergencies: EmergencyResponse[];
  public page: number = 0;
  public first = true;
  public last = true;
  public $log:ILogService;

  public jumpToDate: Date;
  public isDateSearch = false;
  public filter = {
    searchFilter: '',
    reverseOrdering: true
  } as PageFilter;

  public isLoading = false;
  public account: UserAccount;

  public onlyOwnMissions: boolean = false;

  private listeners = [];

  constructor(private $scope: IScope,
    private $rootScope: IRootScopeService,
    private $uibModal: any,
    private dataService: any,
    private restService: RestService,
    public privilegeService: PrivilegeService, $log:ILogService) {

    this.jumpToDate = new Date();
    this.jumpToDate.setMilliseconds(0);
    this.jumpToDate.setSeconds(0);

    this.account = this.dataService.getAccount();
    this.initListeners();
    this.load();
    this.$log = $log;this.$log = $log;
  }
  updateColumnOrdering() {
    this.filter.reverseOrdering = !this.filter.reverseOrdering;
    this.load();
  }

  refresh(){
    this.page = 0;
    this.load();
  }

  searchForDate() {
    if (!this.jumpToDate) return;
    this.isDateSearch = true;
    this.page = 0;
    this.load();
  }

  resetDateSearch() {
    this.isDateSearch = false;
    this.jumpToDate = new Date();
    this.jumpToDate.setMilliseconds(0);
    this.jumpToDate.setSeconds(0);
    this.page = 0;
    this.load();
  }
  /**
   Search for specific alarm
   */
  onSearchFilterChanged() {
    this.page = 0; // Reset current page to first page
    this.load();
  };

  /**
   Reset the search filter
   */
  resetSearchFilter() {
    this.filter.searchFilter = '';
    this.page = 0; // Reset current page to first page
    this.load();
  };

  load() {
    this.account = this.dataService.getAccount();
    this.isLoading = true;

    let ordering = this.filter.reverseOrdering ? 'DESC' : 'ASC';

    this.restService.getAllEmergencies(this.page, 50, this.filter.searchFilter, ordering, this.isDateSearch ? this.jumpToDate.getTime() : 0, this.onlyOwnMissions)
      .then(emergencies => {
        this.page = emergencies.number;
        this.first = emergencies.first;
        this.last = emergencies.last;
        this.emergencies = emergencies.content.filter(emergency => emergency.externalId)
      })
      .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync()
      });
  }

  initListeners() {
    //Wait for new account
    this.listeners.push(this.$rootScope.$on('new.account', (event, account) => {
      this.$log.debug('Missions: new.account triggered!');
      this.account = this.dataService.getAccount();
      //reload missions on account switch
      this.load();
    }));
    //Wait for LOGOUT
    this.listeners.push(this.$rootScope.$on('delete.account', () => {
      this.$log.debug('Missions: delete.account triggered!');
      this.emergencies = undefined;
    }));

    this.listeners.push(this.$rootScope.$on('mission.deleted', () => {
      this.$log.debug('Missions: mission.deleted triggered!');
      this.load();
    }));


    //Wait for new alarms
    this.listeners.push(this.$rootScope.$on('new.alarm', (event, alarm: AlarmDataSimple) => {
      let hasInList = this.emergencies.filter(emergency => emergency.externalId === alarm.externalId).length > 0;

      if (!hasInList && this.page === 0) {

        if (this.filter.searchFilter) {
          // Don't add emergency if filter is active
          return;
        }

        // Convert to emergency
        let newAlarm = {
          externalId: alarm.externalId,
          keyword: alarm.keyword,
          location_dest: alarm.locationDest,
          timestamp: alarm.time,
          vehicles: alarm.vehicles,
          color: 'red'
        } as EmergencyResponse;
        this.emergencies.unshift(newAlarm);
      }
    }));

    // Wait for deletion of an alarm
    this.listeners.push(this.$rootScope.$on('single.alarm.deleted', (event, alarm: AlarmDataSimple) => {
      this.load();
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  previousPage() {
    if (!this.first) {
      this.page--;
      this.load();
    }
  }

  nextPage() {
    if (!this.last) {
      this.page++;
      this.load();
    }
  }

  toggleOwnMissions() {
    this.load();
  }
}
interface PageFilter {
  searchFilter: string,
  orderByField: string,
  reverseOrdering: boolean
}