'use strict';
//objectAccess
export default class ObjectAccessComponent {
  constructor() {
    this.restrict = 'A'
    this.template = require('./object.access.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectAccessComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectAccessComponentController {
  constructor($uibModal) {
    this.$uibModal = $uibModal;
    this.types = ['PRIMARY', 'SECONDARY'];
  }

  // ### ACCESS GATES ###
  /**
   * Add a new access gate
   */
  addAccessGate() {
    var data = {
      note: '',
      street: '',
      type: 'PRIMARY',
      coords: {
      }
    };
    if (angular.isDefined(this.alarmObject.address) && angular.isDefined(this.alarmObject.address.coords)) {
      data.coords.lat = this.alarmObject.address.coords.lat;
      data.coords.lng = this.alarmObject.address.coords.lng;
      data.coords.showOnMap = true;
    }
    if (angular.isUndefined(this.alarmObject.address.accessGates)) {
      this.alarmObject.address.accessGates = [];
    }
    this.alarmObject.address.accessGates.push(data);
  };

  /**
   * Show access gate on map
   * @param {*} accessGate 
   */
  show(accessGate) {
    if (this.isEditable) {
      this.$uibModal.open({
        template: require('../../../modals/objects/choose.on.map.modal/choose.on.map.modal.html'),
        controller: 'ChooseOnMapCtrl',
        controllerAs: 'ctrl',
        size: 'lg',
        resolve: {
          coords: () => {
            return accessGate.coords;
          },
          okFunction: () => {
            return (coords) => {
              accessGate.coords = coords;
            }
          }
        }
      });
    }
  };

  /**
   * Delete existing access gate
   * @param {*} data
   */
  deleteAccessGate(data) {
    this.alarmObject.address.accessGates.splice(this.alarmObject.address.accessGates.indexOf(data), 1);
  };
}