'use strict';

import { Relais } from "../../../../data/admin.data";
import { Gate, GateUpdateRequest, ListParkingPosition, ParkingPositionUpdateRequest, RelaisNameResponse } from "../../../../data/building.data";
import { RolePrivilege } from "../../../../data/privileges.enum";
import HelperService from "../../../../services/helper.service";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";

require("./gate.admin.component.css")

export default class GateAdminComponent {

  private restrict: any;
  private scope: any;
  private controller: any;
  private template: any;
  private controllerAs: string;
  private bindToController: boolean;

  constructor() {
    this.restrict = 'EA'
    this.scope = {
    }
    this.template = require('./gate.admin.component.html');

    this.controller = GateAdminController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//parking
/* @ngInject */
class GateAdminController {

  public gates: Gate[];
  public relais: RelaisNameResponse[];

  private relaisIdNameMapping: any = {};

  private isLoading = false;

  constructor(
    private $scope: any,
    private $rootScope: any,
    private $log: any,
    private restService: RestService,
    private $uibModal: any) { // TODO: maybe we can remove $uibModal
    this.loadGates();
    this.loadRelais();
  }

  private loadGates() {
    this.isLoading = true;
    this.restService.loadAllGates()
      .then(gates => this.gates = gates)
      .catch(err => this.$log.error(err))
      .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
  }

  private loadRelais() {
    this.isLoading = true;
    this.restService.getRelaisNameResponses()
      .then(relais => {
        this.relais = relais;
        this.relaisIdNameMapping = {};
        for (let r of relais) {
          this.relaisIdNameMapping[r.id] = r.name;
        }
      })
      .catch(err => this.$log.error(err))
      .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
  }

  public add() {
    this.isLoading = true;
    this.restService.addGate()
      .then(_ => this.loadGates())
      .catch(err => this.$log.$error(err))
      .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
  }

  public delete(gate: Gate) {
    this.isLoading = true;
    this.restService.deleteGate(gate)
      .then(() => this.loadGates())
      .catch(error => this.$log.error(error))
      .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
  }

  public selectRelaisOpen(gate: Gate, relaisId: string) {
    let request: GateUpdateRequest = {
      openRelaisId: relaisId,
      closeRelaisId: gate.gateCloseId,
      name: gate.name
    };
    this.updateGate(gate.id, request);
  }

  public selectRelaisClose(gate: Gate, relaisId: string) {
    let request: GateUpdateRequest = {
      openRelaisId: gate.gateOpenId,
      closeRelaisId: relaisId,
      name: gate.name
    };
    this.updateGate(gate.id, request);
  }

  public editName(gate: Gate) {
    this.$uibModal.open({
      template: require('../../../modals/misc/change.string.modal/change.string.modal.html'),
      controller: 'ChangeStringModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'md',
      resolve: {
        str: () => {
          return gate.name;
        },
        okFunction: () => {
          return (newName: string) => {
            if (newName === '') return;
            this.renameGate(gate, newName);
          }
        }
      }
    });
  }

  private renameGate(gate: Gate, name: string) {
    let request: GateUpdateRequest = {
      openRelaisId: gate.gateOpenId,
      closeRelaisId: gate.gateCloseId,
      name: name
    };
    this.updateGate(gate.id, request);
  }

  private updateGate(gateId: string, request: GateUpdateRequest) {
    this.isLoading = true;
    this.restService.updateGate(gateId, request)
      .then(_ => this.loadGates())
      .catch(err => this.$log.error(err))
      .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
  }

  public getRelaisName(id: string) {
    return this.relaisIdNameMapping[id];
  }

}