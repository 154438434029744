'use strict';

export default class AvailabilityFutureComponent {
  constructor() {
    this.restrict = 'E',
      this.scope = {
        onlineservice: '=',
        mode: '='
      }
    this.template = require('./future.component.html');

    this.controller = AvailabilityFutureController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//availabilityFuture
/* @ngInject */
class AvailabilityFutureController {
  constructor($scope, $rootScope, $log, dataService, helperService) {

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.dataService = dataService;
    this.helperService = helperService;

    this.date = new Date();
    this.personsWithPlanning = [];
    this.data = {
      threshold: this.helperService.getFromStorage('threshold', 50),
      hours: 12,
      hide: true,
      selected: false,
      opened: false,
      nbrOfLoadedPersons: 0
    }
    this.dateOptions = {
      formatYear: 'yy',
      startingDay: 1
    }
    this.hover = {
      hour: -1,
      date: $scope.date
    }
    this.format = 'dd.MM.yyyy';
    this.loaded = false;
    this.statistic = [];

    this.listeners = [];
    this.initListeners();
    // INIT
    this.selectDate(false);


    this.$scope.$watch('ctrl.onlineservice', (nVal) => {
      if (angular.isUndefined(nVal)) {
        return;
      }
      this.loaded = false;
    });

    this.$scope.$watch('ctrl.mode', (nVal) => {
      if (angular.isUndefined(nVal)) {
        return;
      }
      this.data.selected = nVal === 'FUTURE';
      if (!this.data.selected) {
        this.data.nbrOfLoadedPersons = 0;
      }
    })

    this.$rootScope.$eventToObservable('future.update').debounce(300).subscribe((val) => {
      this.loaded = true;
    });

    this.$rootScope.$eventToObservable('future.update.event').subscribe(() => {
      this.data.nbrOfLoadedPersons++;
    });
  }

  initListeners() {

    //Wait for LOGOUT
    this.listeners.push(this.$rootScope.$on('future.hovering', (event, data) => {
      this.hover = data;
      this.calculateFunctionStatistic();
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  open() {
    this.data.opened = true;
  }

  /**
   * Get monday from current week
   */
  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  /**
   * Emit threshold event
   */
  changeThreshold() {
    this.$scope.$emit('threshold.update', this.data.threshold);
    this.data.hours = Math.round(24 / 100 * this.data.threshold);
    this.helperService.saveInStorage('threshold', this.data.threshold);
  };

  /**
   * Select a date and update forecast
   */
  selectDate(update) {
    this.dates = [];
    var monday = this.getMonday(this.date);
    this.dates.push(monday);
    for (var i = 1; i < 7; i++) {
      var d = new Date(monday);
      var dateValue = d.getDate() + i;
      d.setDate(dateValue);
      this.dates.push(d);
    }
    this.dataService.clearFutureCache();
    this.data.selected = true;
  }

  /**
   * Returns true if the dates are the same (ignoring hours and minutes)
   * @param {*} d1 
   * @param {*} d2 
   */
  dateEquals(d1, d2) {
    var date1 = new Date(d1);
    var date2 = new Date(d2);
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);
    return date1.getTime() === date2.getTime();
  }

  /**
   * Calculate function statistic
   */
  calculateFunctionStatistic() {

    // Iterate over every date
    this.statistic = {
      date: this.hover.date,
      hour: this.hover.hour,
      functions: {},
      groups: {},
      totalAvailable: 0,
      totalNotAvailable: 0
    }
    var threshold = this.data.threshold;
    var map = this.dataService.getWeeklyAvailability();
    var lst = this.onlineservice.data.lstOfAvailabilities;

    for (var i = 0; i < lst.length; i++) {
      var person = lst[i];
      var stat = map[person.id];
      if (angular.isUndefined(stat)) {
        //Not defined
        continue;
      }

      // Iterate over every date
      for (var ii = 0; ii < stat.entries.length; ii++) {
        var entry = stat.entries[ii];
        if (!this.dateEquals(entry.date, this.statistic.date)) {
          continue;
        }
        var hourEntry = entry.entries[this.hover.hour];
        var isAvailable = true;
        if (hourEntry.state === 'AVAILABLE') {
          isAvailable = hourEntry.percentage >= threshold;
        } else {
          isAvailable = 100 - hourEntry.percentage >= threshold;
        }
        if (isAvailable) {
          this.statistic.totalAvailable++;
        } else {
          this.statistic.totalNotAvailable++;
        }

        // Functions
        person.functionsAsList.forEach((func) => {
          if (this.statistic.functions[func] === undefined) {
            this.statistic.functions[func] = {
              totalAvailable: 0,
              totalNotAvailable: 0
            };
          }
          if (isAvailable) {
            this.statistic.functions[func].totalAvailable++;
          } else {
            this.statistic.functions[func].totalNotAvailable++;
          }
        });

        // Groups
        person.groupsAsList.forEach((func) => {
          if (this.statistic.groups[func] === undefined) {
            this.statistic.groups[func] = {
              totalAvailable: 0,
              totalNotAvailable: 0
            };
          }
          if (isAvailable) {
            this.statistic.groups[func].totalAvailable++;
          } else {
            this.statistic.groups[func].totalNotAvailable++;
          }
        });
        break;
      }
    }
  }
}