'use strict';

/* @ngInject */
export default class ModalShowHelpController {
    constructor($scope, $uibModalInstance, $sce, urlToWiki) {
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.$sce = $sce;
        this.urlToWiki = this.$sce.trustAsResourceUrl(urlToWiki);;
    }

    cancel() {
        this.$uibModalInstance.close();
    }
}