'use strict';

import angular = require("angular");
import {ProvisioningSimple, Provisioning, ProvisioningSound, ProvisioningGeoFenceSelection, ProvisioningGeoFenceExclusionData} from "../../../../data/provisioning.data";
import RestService from "../../../../services/rest.service";
import {SettingsMode} from "../../misc/settings.modal/settings.modal";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";

require('./provisioning.modal.css');

//Controller for provisioning configuration
//ProvisioningInstanceCtrl
/* @ngInject */
export default class ProvisioningInstanceModalController {
  public $scope: angular.IScope;
  public $rootScope: angular.IRootScopeService;
  public $log: angular.ILogService;
  public $uibModal: any;
  public $uibModalInstance: any;
  public dataService: any;
  public helperService: any;
  public provisioning: Provisioning;
  public soundFiles: String[];
  public keys: any;
  public listeners: any;
  public availabilityMode: string;
  public account: any;
  public hasWasserkarteToken: boolean;
  public form: string;
  public isLoading: boolean;
  public allAvailableGeofences: ProvisioningGeoFenceSelection[];
  public restService:RestService;
  public priv:PrivilegeService;
  public hasProvisionEdit:boolean= false;
  public hasSettings:boolean= false;
  public hasSettingsWass:boolean= false;
  public isToggleSoundsVisible:boolean= true;

  public baseUrl: string;
  public state: ModalState = ModalState.SETTINGS;

  constructor($scope: angular.IScope, $rootScope: angular.IRootScopeService, $log: angular.ILogService, $uibModal, $uibModalInstance, dataService, restService: RestService, helperService, provisioning: ProvisioningSimple, privilegeService:PrivilegeService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.$uibModalInstance = $uibModalInstance;
    this.dataService = dataService;
    this.restService = restService;
    this.baseUrl = restService.getBaseUrl();
    this.helperService = helperService;
    this.soundFiles = this.helperService.getSounds();
    this.keys = [];
    this.listeners = [];
    this.availabilityMode = 'AV_NONE';
    this.account = dataService.getAccount();
    this.priv = privilegeService;

    this.initListeners();



    // Now load provisioning
    this.isLoading = true;
    this.dataService.getSingleProvisioning(provisioning.id, (response: Provisioning) => {
      this.provisioning = response;
      this.initProvisioning();
      if (this.account.hasOnlineService) {
        // Inital load
        this.load();
      }
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
    });
  }

  private switchState(newState: ModalState) {
    if (newState === ModalState.GEO_FENCES){
      this.isLoading = true;
      this.restService.loadAllGeofenceProvisioningsForUser().then((result)=>{
        this.allAvailableGeofences = result;
        this.isLoading = false;
        this.$scope.$applyAsync();
      }).catch((error)=>{
        this.$log.error(error.data.message);
      }).finally(()=>{
        this.isLoading = false;
      })
    }
    this.state = newState;
  }

  isGeofenceDeactivated(geofence:ProvisioningGeoFenceSelection){
    if (angular.isUndefined(this.provisioning.data.geoFenceExclusionData)){
      this.provisioning.data.geoFenceExclusionData= [];
      return false;
    }
    if (this.provisioning.data.geoFenceExclusionData.some((geofenceExclusion)=>geofenceExclusion.geofenceId === geofence.id)){
      return true;
    }
  }
  deactivateGeofence(geofence:ProvisioningGeoFenceSelection){
    // already in deactivated list
    if (this.provisioning.data.geoFenceExclusionData.some((geofenceExclusion)=>geofenceExclusion.geofenceId === geofence.id)){
      return;
    }
    var data = {geofenceId: geofence.id} as ProvisioningGeoFenceExclusionData;
    this.provisioning.data.geoFenceExclusionData.push(data);
  }

  activateGeofence(geofence:ProvisioningGeoFenceSelection){
    var data = this.provisioning.data.geoFenceExclusionData.find((geofenceExclusion)=>geofenceExclusion.geofenceId === geofence.id)
    if (angular.isDefined(data)){
      this.provisioning.data.geoFenceExclusionData.splice(this.provisioning.data.geoFenceExclusionData.indexOf(data ), 1);
    }
  }

  /**
   * Init fields after getting provisioning profile
   */
  private initProvisioning(): void {
    if (this.account.settings.wasserkarteInfoToken) {
      this.hasWasserkarteToken = true;
    } else {
      this.hasWasserkarteToken = false;
    }

    if (this.provisioning.data.osApiKey) {
      if (this.provisioning.data.osApiKey === this.account.apiKeyReadOnly) {
        this.availabilityMode = 'AV_FULL';
      } else {
        //Save api key temporary
        this.availabilityMode = this.provisioning.data.osApiKey;
      }
    } else {
      this.availabilityMode = 'AV_NONE';
    }
    this.hasProvisionEdit= this.priv.has(RolePrivilege.Addressbook_Provisioning_Edit);
    this.hasSettings = this.priv.has(RolePrivilege.Settings);
    this.hasSettingsWass= this.priv.has(RolePrivilege.Settings_Wasserkarte);
  }

  validate() {
    this.$log.info('Validating form...');
    /*
    Due to old provisionings we have to check if somebody entered a text and if yes, we delete the text.
    The validation on the PIN field will then force to use a number if the checkbox is checked.
    */
    if (this.provisioning.data.supervisedPin && isNaN(this.provisioning.data.supervisedPin as any)) {
      this.form['supervised'].$setValidity('supervised', false);
    } else {
      this.form['supervised'].$setValidity('supervised', true);
    }
  }

  cancel() {
    this.$uibModalInstance.close();
  };

  /**
   * Manually update show availability
   */
  setShowFullAvailability() {
    this.provisioning.data.osApiKey = this.account.apiKeyReadOnly;
    this.availabilityMode = 'AV_FULL';
  }

  /**
   * Reset show availability
   */
  resetShowAvailability() {
    delete this.provisioning.data.osApiKey;
    this.availabilityMode = 'AV_NONE';
  };

  /**
   * Select a availablility API key
   * @param  key
   */
  selectApiKey(key) {
    this.provisioning.data.osApiKey = key.apiKey;
    this.availabilityMode = key.name;
  };

  /**
   * Save the provisioning configuration
   */
  save() {
    this.isLoading = true;
    this.dataService.saveProvisioning(this.provisioning, (data: Provisioning) => {
      this.isLoading = false;
      this.$uibModalInstance.close();
      this.$rootScope.$emit('provision.updated', data);
    }, (err) => {
      this.isLoading = false;
      this.$log.error(err);
    });
  }

  /**
   * Open a sound file to play
   * @param sound The sound to open
   */
  openSoundPreview(soundName: string) {
    window.open(this.baseUrl + '/files/sounds?name=' + encodeURIComponent(soundName), '_blank');
  }

  /**
   * Add a new sound
   */
  addSound() {
    const sound = {
      name: '',
      file: 'default',
      matching: true,
      autoGenerated: false,
      visible: true
    } as ProvisioningSound;
    this.provisioning.data.sounds.push(sound);
  }

  /**
   * Delete existing sound
   * @param sound Sound to delete
   */
  deleteSound(sound: ProvisioningSound) {
    this.provisioning.data.sounds.splice(this.provisioning.data.sounds.indexOf(sound), 1);
  }

  /**
      Opens email to admin popup
    */
  openSettings(settingsTab: SettingsMode) {
    const modalInstance = this.$uibModal.open({
      template: require('../../../modals/misc/settings.modal/settings.modal.html'),
      controller: 'SettingsInstanceModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        settingsTab: () => {
          return settingsTab;
        }
      }
    });

    // Refresh settings
    modalInstance.result.then(() => {
      this.restService.getTermsOfUse().then(termsOfUse => {
        this.provisioning.hasPrivacyLink = termsOfUse.hasPrivacyLink;
        this.provisioning.hasTermOfUses = termsOfUse.hasTermOfUses;
      }).finally(() => {
        this.$scope.$applyAsync();
      });
    });
  }

  /**
   * Load API keys
   */
  load() {
    this.dataService.getApiKeys(false, (keys) => {
      this.$log.debug('API keys loaded: ' + keys.length);
      this.keys = keys;
      // Check if key is currently selected
      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        if (key.apiKey === this.availabilityMode) {
          this.availabilityMode = key.name;
          break;
        }
      }
    }, (err) => {
      this.$log.error('Could not load API keys');
      this.$log.error(err);
    });
  }

  toggleAllVisibility(){
    if (this.isToggleSoundsVisible){
      this.isToggleSoundsVisible= false;
      this.provisioning.data.sounds.forEach(sound=> sound.visible =false);
    }else {
      this.isToggleSoundsVisible= true;
      this.provisioning.data.sounds.forEach(sound=> sound.visible =true);
    }

  }

  initListeners() {
    this.listeners.push(this.$rootScope.$on('new.settings', (event, settings) => {
      //Update settings
      this.account = this.dataService.getAccount();
      this.initProvisioning();
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }
}

enum ModalState {
  SETTINGS = 'SETTINGS', SOUNDS = 'SOUNDS', GEO_FENCES='GEO_FENCES'
}
