'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import { VehicleAssignedAlarm } from "../../../../data/alarm.data";
import { RolePrivilege } from "../../../../data/privileges.enum";
import { StatusEntryResponse } from "../../../../data/vehicles.data";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";

require('./alarm.assigned.row.entry.component.scss');

export default class AlarmAssignedRowEntryComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'A';
    this.template = require('./alarm.assigned.row.entry.component.html');
    this.scope = {
      alarm: '='
    };
    this.controller = AlarmAssignedRowEntryComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  };
};
class AlarmAssignedRowEntryComponentController {
  public alarm: VehicleAssignedAlarm;
  private $rootScope: IRootScopeService;
  private $log: ILogService;
  private $uibModal: any;
  private $scope: IScope;
  private listeners = [];
  private restService: RestService;
  private isLoading: boolean= false;
  constructor($rootScope: IRootScopeService, $log: ILogService, $scope: IScope, private privilegeService: PrivilegeService, $uibModal: any,restService: RestService) {
    this.$uibModal = $uibModal;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$log = $log;
    this.restService = restService;

    this.$scope.$watch('ctrl.alarm', (oldValue, newVehicle: VehicleAssignedAlarm) => {
      if (newVehicle && this.listeners.length === 0) {
        // Register for topic
        const changeEvent = 'status.change.' + this.alarm.vehicleId;
        this.$log.debug(`Register for change events of ${newVehicle.vehicleName} on ${changeEvent}`);
        this.listeners.push(this.$rootScope.$on(changeEvent, (event, data: StatusEntryResponse) => {
          this.alarm.status = data.status;
          this.alarm.statusChangedTimestamp = new Date(data.timestamp);
          this.alarm.statusColor = data.color;
          this.alarm.statusTextColor = data.textColor;
          this.reloadOnStatusChange();
          this.$scope.$applyAsync();
        }));
      }
    });

    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });

  }

  openMission() {
    if (!this.privilegeService.has(RolePrivilege.Home_Emergency_Details)) {
      return;
    }
    this.$uibModal.open({
      template: require('../../../modals/alarms/mission.modal/mission.modal.html'),
      controller: 'MissionModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'hu',
      resolve: {
        externalId: () => this.alarm.externalId,
        parentUserId: () => undefined
      }
    })
  }

  openMap() {
    if (this.isLoading){
      // don't open map while reloading current location with current status
      return;
    }
    this.$uibModal.open({
      template: require('../../../modals/alarms/choose.on.map.modal/choose.on.map.modal.html'),
      controller: 'ChooseAlarmOnMapController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        coords: () => {
          return {
            lat: this.alarm.lat,
            lng: this.alarm.lng,
            accuracy: 0
          }
        },
        position: () => {
          return this.alarm.currentPosition;
        },
        okFunction: () => {
          return (coords) => {
            // Nothing to do
          }
        }
      }
    });

  }

  private reloadOnStatusChange() {
    // only reload if a position was available
    if (this.alarm.hasPosition){
      this.isLoading = true;
      // simply reload so every data field is automatically set correctly
      this.restService.loadVehicleLocation(this.alarm.vehicleId).then((response) => {
        this.alarm.currentPosition= response;
      }).finally(()=>{
        this.isLoading= false;
      })
    }

  }
}
