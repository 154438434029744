'use strict'

import { IRootScopeService, IScope, IIntervalService } from "angular";
import { Queue } from "../../../data/queue.data";
import { DWDWeather, DWDWeatherWarning, PegelWarning } from "../../../data/weather.data";
import RestService from "../../../services/rest.service";
import * as L from "leaflet";
import MapService from "../../../services/map.service";
import { UserAccount } from "../../../data/account.data";
import HelperService from "../../../services/helper.service";

require('./queues.view.component.scss');

/* @ngInject */
export default class QueuesController {
  public isLoading = false;
  private listeners = [];
  private queues: Queue[] = [];
  public summary: Summary = {
    nbrOfQueues: 0
  };

  private clockTicker;
  private weatherTicker;
  private wmsRainTicker;
  public clock: Date = new Date();
  private weather: DWDWeather;
  public warnings: DWDWeatherWarning[] = [];
  public pegelWarnings: PegelWarning[] = [];

  public map;
  public radarOpened = false;
  public rainRadarLayer: L.TileLayer.WMS;

  public displayFinishedColumn = false;

  constructor(private $scope: IScope,
    private $rootScope: IRootScopeService,
    private restService: RestService,
    private $interval: IIntervalService,
    private dataService,
    public mapService: MapService,
    public helperService: HelperService) {
    this.initListeners();
    // Initial load
    this.load();
    this.loadWeatherAndWarnings();
  }


  load() {

    this.displayFinishedColumn = this.helperService.getFromStorage('QUEUE.DISPLAY_FINISHED', false);


    this.isLoading = true;
    this.restService.loadQueues()
      .then(queues => this.queues = queues)
      .then(() => {
        this.summary = {
          nbrOfQueues: this.queues.length
        };
      })
      .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
  }

  loadWeatherAndWarnings() {
    this.restService.loadWeather().then(currentWeather => {
      this.weather = currentWeather.weather;
    }).then(() => {
      this.restService.loadWeatherWarnings().then(warnings => {
        this.warnings = warnings.weatherWarnings;
        this.pegelWarnings = warnings.pegelWarnings;
      }).finally(() => this.$scope.$applyAsync());
    });
  }

  toggleDisplayFinishedColumn() {
    this.displayFinishedColumn = !this.displayFinishedColumn;
    this.helperService.saveInStorage('QUEUE.DISPLAY_FINISHED', this.displayFinishedColumn);
  }

  /**
   * Toggle Radard opened button and initialize map if necessary
   */
  openRadardAndInitMap() {
    this.radarOpened = !this.radarOpened;

    if (this.radarOpened) {
      setTimeout(() => {
        let userAccount: UserAccount = this.dataService.getAccount() as UserAccount;
        L.Icon.Default.imagePath = '/img/static';

        const latLng = L.latLng(userAccount.settings.lat, userAccount.settings.lng);

        this.map = L.map('queue-weather-radar', { attributionControl: true, zoomControl: false }).setView(latLng, 10);
        let layers = this.mapService.getBaseLayers();
        let selectedLayer = this.mapService.getSelectedLayer();
        if (selectedLayer == undefined || layers[selectedLayer] == undefined) {
          selectedLayer = "OpenStreetMap";
        }
        layers[selectedLayer].addTo(this.map);

        // Rain radar
        this.rainRadarLayer = L.tileLayer.wms('https://maps.dwd.de/geoserver/dwd/wms', {
          maxZoom: 19,
          id: 'Regenradar',
          attribution: '© Deutscher Wetterdienst',
          layers: 'dwd:Niederschlagsradar',
          format: 'image/png',
          transparent: true,
          opacity: 0.5,
          zIndex: 11
        });
        this.rainRadarLayer.addTo(this.map);

        // Start ticker
        this.wmsRainTicker = this.$interval(() => {
          // Force reload
          this.rainRadarLayer.setParams({} as L.WMSParams);
        }, 1000 * 60);

      }, 500);
    } else {
      if (this.wmsRainTicker) {
        this.$interval.cancel(this.wmsRainTicker);
      }
    }

  }

  initListeners() {
    this.listeners.push(this.$rootScope.$on('new.account', (event, account) => {
        this.load();
    }));
    this.listeners.push(this.$rootScope.$on('metainformation.change', (event, account) => {
      this.loadWeatherAndWarnings();
    }));



    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
      this.$interval.cancel(this.clockTicker);
      this.$interval.cancel(this.weatherTicker);

      if (this.wmsRainTicker) {
        this.$interval.cancel(this.wmsRainTicker);
      }
    });

    this.clockTicker = this.$interval(() => {
      this.clock = new Date();
    }, 1000);


    this.weatherTicker = this.$interval(() => {
      // Load weather
      this.loadWeatherAndWarnings();

    }, 1000 * 60 * 30); // Every 30 Minutes
  }
}

interface Summary {
  nbrOfQueues: number;
}