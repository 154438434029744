'use strict';

/* @ngInject */
export default class AddAlarmObjectController {
  constructor($uibModalInstance, okFunction) {
    this.$uibModalInstance = $uibModalInstance;
    this.okFunction = okFunction;
    this.name = '';
  }

  cancel () {
    this.$uibModalInstance.close();
  }
  ok () {
    if (this.name == '') {
        return;
    }
    this.$uibModalInstance.close('cancel');
    this.okFunction(this.name);
  }
}