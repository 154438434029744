'use strict';
//objectDangerPersons
export default class ObjectDangerPersonsComponent {
  constructor() {
    this.restrict = 'A'
    this.template = require('./object.danger.persons.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectDangerPersonsController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectDangerPersonsController {
  constructor() {
  }

  /**
   * Add a new person
   */
  addPerson() {
    var data = {
      location: '',
      count: '',
      note: '',
      type: ''
    }
    if (angular.isUndefined(this.alarmObject.risks.persons)) {
      this.alarmObject.risks.persons = [];
    }
    this.alarmObject.risks.persons.push(data);
  };

  /**
   * Delete existing person
   * @param {*} persons 
   */
  deletePerson(data) {
    this.alarmObject.risks.persons.splice(this.alarmObject.risks.persons.indexOf(data), 1);
  };
}