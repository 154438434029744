import { IScope } from "angular";
import { ChatroomResponse } from "../../../../data/chat.data";

require("./chat.details.modal.scss")

interface ChatPerson {
    aPager: string,
    admin: boolean
}
export default class ChatDetailsModalController {

    public persons: ChatPerson[];

    constructor(
        public readonly chatroom: ChatroomResponse,
        private readonly $uibModalInstance: any) {

        this.persons = this.getPersons();

    }

    public close() {
        this.$uibModalInstance.close();
    }

    public getPersons() {
        const persons: ChatPerson[] = this.chatroom.invitedPersons
            .map(person => this.mapPerson(person));
        persons.sort(adminsFirst);
        return persons;
    }

    public mapPerson(aPager: string): ChatPerson {
        const admin = this.chatroom.admins.includes(aPager);
        return { aPager, admin }
    }

}

function adminsFirst(left: ChatPerson, right: ChatPerson): number {
    if (left.admin && !right.admin) {
        return -1;
    }
    if (!left.admin && right.admin) {
        return 1;
    }
    return left.aPager.localeCompare(right.aPager);
}