'use strict';

require('./import.layer.css');

/* @ngInject */
export default class ModalImportLayerInstanceController {
  constructor($uibModalInstance, $http, uploader, restService, errorService) {
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;
    this.errorService = errorService;
    this.$http = $http;
    this.uploader = uploader;
    this.uploader.headers = {
      Authorization: this.$http.defaults.headers.common.Authorization
    };
    this.uploader.url = this.restService.getBaseUrl() + '/hydrants/';

    this.uploader.filters.push({
      name: 'jsonCsv',
      fn: (item) => {
        var name = item.name.toLowerCase();
        if (name.endsWith('.csv')) {
          return true;
        }
        if (name.endsWith('json')) {
          return true;
        }
        return false;
      }
    });


    this.uploader.onCompleteItem = (item, response, status, headers) => {
      this.$uibModalInstance.close();
    };
    this.uploader.onErrorItem = (item, response, status, headers) => {
      this.$uibModalInstance.close();

      if (response.status === 409) {
        // Conflict
        this.errorService.notify(this.errorService.ERROR_IDS.CONFLICT_LAYER);
      } else {
        this.errorService.notifyWithText(response.message);
      }

    };
  }

  import() {
    this.isUploading = true;
    this.uploader.uploadAll();
  };

  cancel() {
    this.$uibModalInstance.close();
  };
}
