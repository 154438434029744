import angular from 'angular';

import 'angular-ui-router';
import 'angular-ui-bootstrap';
import 'angular-local-storage';
import 'angular-translate';
import 'angular-animate';
import 'angular-sanitize';
import 'angular-ui-notification';
import 'angular-file-upload';
import 'angular-ui-bootstrap/dist/ui-bootstrap-tpls.js';
import 'rx-angular';
import 'angular-chart.js';
import 'angular-bootstrap-colorpicker';
import 'angular-bootstrap-colorpicker/css/colorpicker.min.css';
import 'angular-ui-tree';
import 'qrcode-generator';
import ngQrcode from 'angular-qrcode';
import 'angular-leaflet-directive';
import 'leaflet';
import 'leaflet-draw';

// Vendor style
import 'bootstrap/dist/css/bootstrap.css';
import 'angular-ui-notification/dist/angular-ui-notification.min.css';
import 'angular-ui-tree/dist/angular-ui-tree.min.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/images/layers.png';
import 'leaflet/dist/images/layers-2x.png';
import 'leaflet/dist/images/marker-icon-2x.png';
import 'leaflet/dist/images/marker-icon.png';
import 'leaflet/dist/images/marker-shadow.png';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw/dist/images/spritesheet-2x.png';
import 'leaflet-draw/dist/images/spritesheet.png';
import 'leaflet-draw/dist/images/spritesheet.svg';
import 'leaflet.markercluster/dist/leaflet.markercluster.js';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import './../public/fontawesome/css/fontawesome.min.css';
import './../public/fontawesome/css/all.min.css';
import './../public/fontawesome/css/v5-font-face.min.css';

// Custom style
import './style/main.css';
import './style/availability.css';
import './style/sprite.css';

import views from './components/views';
import routing from './app.config';

let app = () => {
  return {
    template: require('./app.html'),
    controller: 'AppCtrl',
    controllerAs: 'app'
  }
};

class AppCtrl {
  constructor() {

  }
}

const MODULE_NAME = 'FE2';

angular.module(MODULE_NAME, [
    'ui.router',
    'leaflet-directive',
    'ui.bootstrap',
    'ngAnimate',
    'ngSanitize',
    'LocalStorageModule',
    'pascalprecht.translate',
    'ui-notification',
    'angularFileUpload',
    'rx',
    'chart.js',
    'colorpicker.module',
    'ui.tree',
    ngQrcode,
    views
  ])
  .directive('app', app)
  .controller('AppCtrl', AppCtrl)
  .config(routing);

angular.module('ui.tree').config(function (treeConfig) {
  treeConfig.dragMoveSensitivity = 15;
});

export default MODULE_NAME;
