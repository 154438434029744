'use strict';
//mapPin
export default class MapPinComponent {
  constructor() {
    this.restrict = 'E'
    this.template = require('./map.pin.component.html');
    this.scope = {
      coords: '='
    };
    this.controller = MapPinComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class MapPinComponentController {
  constructor($scope) {
    this.$scope = $scope;

    this.$scope.$watch('ctrl.coords', () => {
      this.update();
    });
    this.update();
  }

  update() {
    this.isHidden = angular.isDefined(this.coords) && !this.coords.showOnMap;
    this.isSameAsObject = angular.isUndefined(this.coords);
  };
}