'use strict';

//statusSourceFilter
/* @ngInject */
export default class StatusSourceFilter {
  static filter(source) {
    if (angular.isDefined(source)) {
      if (source === 'MANUAL') {
        return 'Manuell';
      }
      if (source === 'AMOBILE') {
        return 'aMobile';
      }
    }
    return source;
  }
}
