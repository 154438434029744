import angular from 'angular';

import FixedLengthFilter from './number.fixed.length.filter';
import ConditionStringFilter from './condition.string.filter';
import DropdownFilter from './filter.dropdown.filter';
import HumanReadableTimeDiffFilter from './human.readable.diff.filter';
import HumanReadableTimeFilter from './human.readable.time.filter';
import NumberOfGroupsFilter from './number.of.groups.filter';
import NumberOfPersonsFilter from './number.of.persons.filter';
import NumberOfUnitsFilter from './number.of.units.filter';
import PersonFilter from './person.filter';
import PersonSelectFilter from './person.select.filter';
import StartFromFilter from './start.from.filter';
import StatusFilter from './status.filter';
import StatusSourceFilter from './status.source.filter';
import EllipsisFilter from './ellipsis.filter';

export default angular.module('FE2.filters', [])
  .filter('numberFixedLen', () => FixedLengthFilter.filter)
  .filter('conditionString', () => ConditionStringFilter.filter)
  .filter('filterDropdown', () => DropdownFilter.filter)
  .filter('humanReadableDiff', () => HumanReadableTimeDiffFilter.filter)
  .filter('humanReadableTime', () => HumanReadableTimeFilter.filter)
  .filter('numberOfGroups', () => NumberOfGroupsFilter.filter)
  .filter('numberOfPersons', () => NumberOfPersonsFilter.filter)
  .filter('numberOfUnits', () => NumberOfUnitsFilter.filter)
  .filter('personFilter', () => PersonFilter.filter)
  .filter('personSelect', () => PersonSelectFilter.filter)
  .filter('startFrom', () => StartFromFilter.filter)
  .filter('statusFilter', () => StatusFilter.filter)
  .filter('statusSourceFilter', () => StatusSourceFilter.filter)
  .filter('ellipsisFilter', () => EllipsisFilter.filter)
  .name;