'use strict';

/* @ngInject */
export default class ConfirmDeleteRuleModalController {
  constructor($uibModalInstance, okFunction) {

    this.$uibModalInstance = $uibModalInstance;
    this.okFunction = okFunction;
  }


  cancel() {
    this.$uibModalInstance.close();
  };

  ok() {
    this.$uibModalInstance.close();
    this.okFunction();
  };

}
