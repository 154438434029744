'use strict';

import {ILogService, IRootScopeService, IScope} from "angular";
import {Person, DatabasePersonProvisioningRepresentation, PersonSimple} from "../../../../data/person.data";
import {AutomaticProvisioningAppliedMode, Provisioning, ProvisioningResponse, ProvisioningSimple} from "../../../../data/provisioning.data";
import RestService from "../../../../services/rest.service";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
import angular = require("angular");


require('./apager.tablerow.component.css');

export default class ApagerRowComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'A',
      this.scope = {
        person: '=',
        provisionings: '='
      }
    this.template = require('./apager.tablerow.component.html');

    this.controller = ApagerRowController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ApagerRowController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public restService: RestService;
  public Notification: any;
  public dataService: any;
  public listeners: Function[] = [];
  public person: DatabasePersonProvisioningRepresentation;
  public provisioningProfile: ProvisioningSimple;
  public $uibModal;
  public $translate;

  public isLoading: boolean = false;
  public isProvisioning: boolean = false;
  public isLoadingProvisioningVersion: boolean = false;
  public isLastApagerPingTooOld = false;
  public result: ProvisioningResponse;
  public priv: PrivilegeService;
  public hasPerson:boolean= false;
  public hasProvisionAssign= false;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, restService: RestService, Notification, dataService, $uibModal, $translate, privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.Notification = Notification;
    this.$translate = $translate;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.restService = restService;
    this.dataService = dataService;
    this.$uibModal = $uibModal;
    this.priv = privilegeService;

    this.$scope.$watch('ctrl.person', (person: Person) => {
      if (angular.isDefined(person) && this.listeners.length === 0) {
        this.initListeners();
      }
    })
  }

  /**
   * Send a provisioning profile to a given user
   * @param provisioningProfile
   */
  sendProvisioning(provisioningProfile?: ProvisioningSimple) {
    this.isProvisioning = true;
    let id: string;
    if (provisioningProfile) {
      id = provisioningProfile.id;
    } else {
      id = this.person.apagerPersonData.provisioningId;
    }
    this.restService.provision(id, this.person.id, false, true, (result: ProvisioningResponse) => {
      this.isProvisioning = false;
      this.result = result;

      switch (result.appliedMode) {
        case AutomaticProvisioningAppliedMode.VIA_PUSH:
        case AutomaticProvisioningAppliedMode.FALLBACK_VIA_EMAIL:
        case AutomaticProvisioningAppliedMode.FORCE_MODE:
          this.$translate('AutomaticProvisioningAppliedMode.' + result.appliedMode).then((translation) => {
            this.Notification.success({
              message: translation,
              title: this.person.displayName,
            });
          });

          break;
        case AutomaticProvisioningAppliedMode.DID_NOTHING_AS_TYPE_IS_SMS:
          this.$translate('AutomaticProvisioningAppliedMode.DID_NOTHING_AS_TYPE_IS_SMS').then((translation) => {
            this.Notification.warning({
              message: translation,
              title: this.person.displayName,
            });
          });
          break;
      }

      this.checkStatus();
    }, (errorResponse) => {
      //Error occured
      this.isProvisioning = false;
      this.$log.error(errorResponse);
    });
  }

  selectPerson(person: DatabasePersonProvisioningRepresentation) {
    if (!this.hasPerson){
      return;
    }
    this.$uibModal.open({
      template: require('../../../modals/addressbook/edit.person.modal/edit.person.modal.html'),
      controller: 'EditPersonModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        person: {personID: person.id} as PersonSimple,
        openedAsAdmin: false,
        isNew:false
      }
    });
  }

  /**
   * Check the persons aPager PRO status
   * @param {} person
   */
  checkStatus() {
    this.isLoading = true;
    this.restService.checkApagerStatus(this.person, (response) => {
      this.isLoading = false;
      this.person = response.data as DatabasePersonProvisioningRepresentation;

      this.checkApagerProProvisioningInfo();
    }, (errorResponse) => {
      //Error occured
      this.isLoading = false;
      this.$log.error(errorResponse);
    });
  }

  getClassForLabel() {
    if (!this.person) {
      return 'label-primary';
    }
    if (!this.person.apagerPersonData || !this.person.apagerPersonData.version || !this.person.apagerPersonData.reportedVersion || !this.person.apagerPersonData.reportedVersion.version) {
      return 'label-primary';
    }

    if (this.person.apagerPersonData.version === this.person.apagerPersonData.reportedVersion.version) {
      return 'label-success';
    } else if (this.person.apagerPersonData.version > this.person.apagerPersonData.reportedVersion.version && this.person.apagerPersonData.reportedVersion.version !== 0) {
      return 'label-danger';
    }
    return 'label-primary';
  }


  /**
   * Load the current aPager PRO provisioning version info
   * @returns
   */
  checkApagerProProvisioningInfo() {
    if (!this.person.apagerPersonData.provisioningId) {
      return;
    }
    this.isLoadingProvisioningVersion = true;
    this.restService.getProvisioningVersionInfoForDevice(this.person.id).then(response => {
      this.isLoadingProvisioningVersion = false;
      this.person.apagerPersonData.reportedVersion.version = response.version;
      this.person.apagerPersonData.reportedVersion.lastConfirmationTs = response.lastConfirmationTs;
      this.person.apagerPersonData.reportedVersion.role = response.role;

      const diff = (new Date()).getTime() - response.lastConfirmationTs;
      const diffInHours = Math.round(diff / 1000 / 60 / 60);
      this.isLastApagerPingTooOld = diffInHours > 96;

    }).finally(() => {
      //Error occured
      this.isLoadingProvisioningVersion = false;
      this.$scope.$applyAsync();
    });
  }

  initListeners() {
    this.hasPerson= this.priv.has(RolePrivilege.Addressbook_Persons);
    this.hasProvisionAssign= this.priv.has(RolePrivilege.Addressbook_Provisioning_Assign);
    //Register for provisioning change events
    this.listeners.push(this.$rootScope.$on('provision.updated', (event, data: Provisioning) => {
      if (this.person.apagerPersonData?.provisioningId === data.id) {
        //Provision updated
        this.$log.info(`Provisioning profile ${data.name} loaded, check status for ${this.person.displayName}...`);
        this.checkStatus();
      }
    }));
    this.listeners.push(this.$rootScope.$on(' provision.loaded', (event, data: ProvisioningSimple) => {
      if (this.person.apagerPersonData.provisioningId === data.id) {
        this.provisioningProfile= data;
        //Provision updated
        this.$log.info(`Provisioning profile ${data.name} updated, check status for ${this.person.displayName}...`);
        this.checkStatus();
      }
    }));



    //Register for provisioning deleted event
    this.listeners.push(this.$rootScope.$on('provision.deleted', (event, data: Provisioning) => {
      if ( this.person.apagerPersonData.provisioningId === data.id) {
        //Provision updated
        this.$log.info(`Provisioning profile ${data.name} deleted, check status for ${this.person.displayName}...`);
        this.checkStatus();
      }
    }));

    // Register for event to check aPager status
    this.listeners.push(this.$rootScope.$on('person.' + this.person.id + '.checkApagerStatus', () => {
      // Check aPager status
      this.$log.info(`CheckApagerStatus event triggered, check status for ${this.person.displayName}...`);
      this.checkStatus();
    }));

    this.listeners.push(this.$rootScope.$on('person.' + this.person.id + '.provision', (event, provisioning: ProvisioningSimple) => {
      // Send new provisioning to person
      this.$log.info(`Provision event triggered, provision ${this.person.displayName} with profile ${provisioning.name}...`);
      this.sendProvisioning(provisioning);
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }
}
