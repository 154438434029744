'use strict';

import { ILogService, IScope } from "angular";
import angular = require("angular");
import { AlarmGroupSimple } from "../../../../data/alarmgroup.data";
import MessagesService from "../../../../services/messages.service";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";


require('./messages.modal.css');

export default class AlarmMessagesModalController {
  public $uibModalInstance: any;
  public $log: ILogService;
  public $scope: IScope;
  public messagesService: MessagesService;
  public restService: RestService;
  public types: any;
  public schedules: any;
  public isLoading = false;
  public isLoadingAlarmGroups = true;
  public hasOnlineService = true;
  public alarmGroups: AlarmGroupSimple[] = [];
  public message: any;
  public isAdding = false;
  public copyOfModel: any;

  constructor($scope: IScope, $uibModalInstance, $log: ILogService, messagesService: MessagesService, restService: RestService,
    message, hasOnlineService: boolean, public privilegeService: PrivilegeService) {
    this.$uibModalInstance = $uibModalInstance;
    this.$log = $log;
    this.$scope = $scope;
    this.messagesService = messagesService;
    this.restService = restService;
    this.types = ['INFO'];
    // this.types = ['INFO', 'REQUEST_AVAILABILITY']; // TODO for later use
    this.schedules = ['ONCE', 'REPEATING'];
    this.hasOnlineService = hasOnlineService;

    // Load all alarm groups
    this.restService.loadAlarmGroupsSimple().then((alarmGroups) => {
      this.alarmGroups = alarmGroups;
    }).finally(() => {
      this.isLoadingAlarmGroups = false;
      this.$scope.$applyAsync();
    });

    this.initDataModel(message);
  }

  /**
   * Init the datamodel for all forms
   * @param {The message} message
   */
  initDataModel(message) {
    if (angular.isDefined(message)) {
      this.$log.debug(message);
      // Editing
      // Update fields
      if (message.schedule.timeRepeating) {
        message.schedule.timeRepeating = new Date(message.schedule.timeRepeating);
      }
      if (message.schedule.timeOnce) {
        message.schedule.timeOnce = new Date(message.schedule.timeOnce);
      }
      this.message = message;
      this.isAdding = false;
      this.copyOfModel = angular.copy(this.message);
    } else {
      // Add new message
      this.isAdding = true;

      var now = new Date();
      now.setSeconds(0, 0);
      this.message = {
        title: '',
        message: '',
        color: '#337ab7',
        type: 'INFO',
        groupIds: [],
        schedule: {
          infoMessageScheduleType: 'ONCE',
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false,
          timeOnce: now,
          timeRepeating: now
        },
        filter: {
          filterForAvailability: false,
          differenceInHours: 72
        }
      };
    }
  }

  isGroupSelected(alarmGroup: AlarmGroupSimple) {
    return this.message.groupIds.indexOf(alarmGroup.id) !== -1;
  }

  /**
   * Select or deselect a alarm group
   * @param {} alarmGroup
   */
  selectGroup($event, alarmGroup: AlarmGroupSimple) {
    $event.stopPropagation();
    var index = this.message.groupIds.indexOf(alarmGroup.id);
    if (index === -1) {
      // Add
      this.message.groupIds.push(alarmGroup.id);
    } else {
      // Remove
      this.message.groupIds.splice(index, 1);
    }
  }


  /**
   * Close the modal
   */
  close() {
    if (angular.isDefined(this.copyOfModel)) {
      angular.forEach(this.copyOfModel, (value, key) => {
        this.message[key] = value;
      });
    }

    this.$uibModalInstance.close();
  }

  /**
   * Delete existing message
   */
  delete() {
    this.isLoading = true;
    this.messagesService.deleteMessage(this.message, () => {
      this.isLoading = false;
      this.close();
    }, (errorResponse) => {
      this.isLoading = false;
      this.$log.error('Could not delete message', errorResponse);
    });
  }


  /**
   * Save the message
   */
  save() {
    this.isLoading = true;

    if (this.isAdding) {
      this.messagesService.addMessage(this.message, () => {
        this.$uibModalInstance.close();
      }, (errorResponse) => {
        this.isLoading = false;
        this.$log.error('Could not add message', errorResponse);
      });
    } else {
      this.messagesService.updateMessage(this.message, () => {
        this.$uibModalInstance.close();
      }, (errorResponse) => {
        this.isLoading = false;
        this.$log.error('Could not save message', errorResponse);
      });
    }
  }
}
